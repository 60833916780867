import * as Toastr from 'toastr';

import Vue from 'vue/dist/vue.esm';
import JsonApi from '../../vendor/json-api';
import CheckoutVue from '../components/checkout/checkout';

export default class Checkout {
  new() {
    let app = new Vue({
      el: '#checkout-app',
      components: { CheckoutVue },
      data: {
        payment: gon.payment,
        shipping_addresses: JsonApi.deserialize(gon.payload.shipping_addresses)
          .data,
        billing_addresses: JsonApi.deserialize(gon.payload.billing_addresses)
          .data,
        order: JsonApi.deserialize(gon.payload.order),
        user: JsonApi.deserialize(gon.payload.user),
      },
      methods: {
        accountUpdated(user) {
          this.user = user;
        }
      }
    });
  }
}
