import Rails from 'rails-ujs';
import * as Toastr from 'toastr';
import scrollPosStyler from 'scrollpos-styler';
import Vue from 'vue/dist/vue.esm';
import CouponCart from './components/checkout/coupon_cart';
import EventBus from './event_bus';

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import VueMask from 'v-mask';
Vue.use(VueMask);

import FBSignInButton from 'vue-facebook-signin-button';
Vue.use(FBSignInButton);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import axios from 'axios';

import controllers from './controllers/main';

import Cart from './components/cart/cart';
import ModalRegister from './components/header/modal_register';
import ModalLogin from './components/header/modal_login'
import ModalPassword from './components/header/modal_password';
import ModalInstructions from './components/header/modal_instructions';
import NewsletterSubscribe from './components/footer/newsletter';
import GtmEvents from "./gtm_events";
import JsonApi from "../vendor/json-api";
class Application {
  run() {
    Rails.start();
    axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken();
    axios.defaults.headers.common['Content-Type'] = 'application/vnd.api+json';

    document.addEventListener('DOMContentLoaded', () => {
      this.initializeControllers();
      this.showFlash();
      this.initializeHeaderApp();
      this.initializeNewsletterApp();
      this.handleKlaviyoVipCodeModal();
      this.header();

      $('[data-toggle="tooltip"]').tooltip();

      $(".just-carousel").slick({
        arrows: false,
        dots: true,
        infinite: false,
      })

      $('.book-appointment').on('click', function () {
        Calendly.initPopupWidget({url: 'https://calendly.com/trejours-tanya/discovery-call'})
        return false;
      });
    });
  }

  showFlash() {
    var that = this;
    $.each(gon.flash, function(_, item) {
      var flashType = that.mapFlashType(item[0]);
      Toastr[flashType](item[1]);
    });
  }

  mapFlashType(value) {
    switch (value) {
      case 'notice':
        return 'warning';
      case 'alert':
        return 'error';
      default:
        return value;
    }
  }

  initializeControllers() {
    const controllerClassName = gon.controller;
    const actionMethodName = gon.action;

    if (controllers[controllerClassName]) {
      const controller = new controllers[controllerClassName]();
      if (controller[actionMethodName]) {
        controller[actionMethodName]();
      }
    }
  }

  initializeHeaderApp() {
    new Vue({
      el: '#header-app',
      components: {
        ModalRegister,
        ModalLogin,
        ModalPassword,
        ModalInstructions,
      },
    });
  }

  header() {
    function searchPlaceholderTextSwapper() {
      const width = window.innerWidth;
      if (width < 1200) {
        $('#term').attr('placeholder', 'Search here...');
      } else {
        $('#term').attr(
          'placeholder',
          'Need help? Search for a type of jewelry or a particular style'
        );
      }
    }

    searchPlaceholderTextSwapper();

    $('#acc-name').html(function(i, h) {
      return h.split(' ')[0] + "'s Account";
    });

    $('#acc-name-mobile').html(function(i, h) {
      return h.split(' ')[0] + "'s Account";
    });
    $('#acc-name').css('display', 'block');

    $('#hamburger').on('click', function() {
      $('#mobile-menu').css('top', '0px');
    });

    $('#close-nav').on('click', function() {
      $('#mobile-menu').css('top', '-120vh');
    });

    $('#search').on('click', function() {
      $('#search-box').css({
        transition: 'opacity 1s',
        opacity: '1',
        visibility: 'visible',
      });
    });

    $('#search-close,#search-submit').on('click', function() {
      $('#search-box').css({
        transition: 'opacity 1s, visibility 1s 1s',
        opacity: '0',
        visibility: 'hidden',
      });
    });

    $(window).on('resize', searchPlaceholderTextSwapper);

    $('.arrow').on('click', function() {
      let menu;
      const idName = this.attributes.id.nodeValue;

      switch (idName) {
        case 'open-occasion-nav':
          menu = $('.occasion-mobile-menu');
          break;
        case 'open-collections-nav':
          menu = $('.collections-mobile-menu');
          break;
        case 'open-jewelry-nav':
          menu = $('.jewelry-mobile-menu');
          break;
        case 'open-hiw-nav':
          menu = $('.hiw-mobile-menu');
          break;
        default:
          menu = $('.acc-menu-mobile');
      }

      if (menu.css('height') == '0px') {
        menu.css('height', menu[0].scrollHeight);
        $('#' + idName).css('transform', 'rotate(90deg)');
      } else {
        menu.css('height', '0px');
        $('#' + idName).css('transform', 'none');
      }
    });
    // cart methods

    // let coupon = new Vue({
    //   el: '#coupon-app',
    //   components: {
    //     CouponCart: CouponCart,
    //   },
    // });

    const cart = new Vue({
      el: '#cart-vue',
      components: {
        Cart: Cart
      }
    });

    const cartContainer = $('#cart-container');
    const overlay = $('.overlay-close');

    $('#shop').on('click', () => {
      GtmEvents.trackCartEvents('view_cart')
      cartContainer.css('right', '0');
      overlay.css('opacity', '1');
      overlay.css('visibility', 'visible');
    });

    $('.overlay-close, #arrow-hide').on('click', () => {
      cartContainer.css('right', '-100vw');
      overlay.css('opacity', '0');
      overlay.css('visibility', 'hidden');
    });

    $(document).on('click','#arrow-hide', function(){
      cartContainer.css('right', '-100vw');
      overlay.css('opacity', '0');
      overlay.css('visibility', 'hidden');
   })
    $('.copy-code').on('click', function() {
      var code = document.getElementById('coupon-ready-code')
      code.select()
      document.execCommand('copy')
      $('.copy-code').text('copied!')
    });

    $('.copy-code').on('click', function() {
      var code = document.getElementById('coupon-ready-code')
      code.select()
      document.execCommand('copy')
      $('.copy-code').text('copied!')
    });
  }

  initializeNewsletterApp() {
    new Vue({
      el: '#newsletter',
      components: { NewsletterSubscribe },
    });
  }

  handleKlaviyoVipCodeModal() {
    var vip_card_form = `form[data-testid="klaviyo-form-${gon.vip_card_form_id}"]`
    var automatic_vip_card_form = `form[data-testid="klaviyo-form-${gon.automatic_vip_card_form_id}"]`
    var vip_card_form_submit = `${vip_card_form} button[type="button"]`
    var automatic_vip_card_form_submit = `${automatic_vip_card_form} button[type="button"]`
  
    $(document).on('click', `${vip_card_form_submit}, ${automatic_vip_card_form_submit}`, function() {
      if($('form span[role="alert"]').length != 0) { return }
      
      var email = $(`${vip_card_form} input[type="email"]`)
      var automatic_form_email = $(`${automatic_vip_card_form} input[type="email"]`)
      
      $.ajax({
        url: '/trejours-vip',
        method: "POST",
        dataType: 'json',
        data: {
          email: email.val() || automatic_form_email.val()
        },
        success: function() {},
        error: function(data) {
          if($('button.klaviyo-close-form').length > 0) {
            $('button.klaviyo-close-form').click()
          } else {
            $('.modal#vipSubscriptionModal').modal('hide')
          }
          
          Toastr.error(data.responseJSON.errors, 'VIP code error');
        }
      })
    })
  }
}

Toastr.options = {
  closeButton: true,
}

let instance = null;

function getInstance() {
  if (!instance) {
    instance = new Application();
  }
  return instance;
}

function run() {
  let application = getInstance();
  application.run();
}

export default Application;
export { Application, run, getInstance };

