<template>
  <div class="hero-wrapper" :class="heroWrapperClass">
    <div class="hero" :style="heroStyle"></div>
    <div class="container">
      <div class="stripes"></div>
      <div class="description description-alt" :style="heroBannerStyle">
        <div class="desc mb-15" :style="heroBannerStyle">
          <div class="focus-title my-10">
            <h1 class="main-text mb-5">
              {{ dynamicMainText }}
            </h1>
            <p class="sub-text">
              {{ dynamicSubText }}
            </p>
          </div>
        </div>

        <div class="text-center">
          <a :href="dynamicBtnLink" class="btn btn-light">
            {{ dynamicBtnText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonApi from "../../../vendor/json-api"
import _ from "lodash";

export default {
  name: 'Hero',
  props: {
    filterValue: {
      type: String,
      required: true
    },
    filterKind: {
      type: String,
      required: true
    },
    mainText: {
      type: String,
      optional: true
    },
    subText: {
      type: String,
      optional: true
    },
    btnText: {
      type: String,
      optional: true
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    collection() {
      if (gon.collection) {
        return JsonApi.deserialize(gon.collection);
      }

      return undefined
    },
    heroWrapperClass() {
      if (this.filterKind == 'type') {
        return `hero-banner-type-${this.filterValue}`;
      }

      return `hero-banner-collection hero-banner-${this.filterKind}-${this.filterValue}`;
    },
    heroBackgroundImage() {
      if (!_.isEmpty(this.collection)) {
        // watch for screen size change and use hero_image_url or hero_mobile_image_url based on screen size
        if (this.windowWidth <= 768) {
          return this.collection.hero_mobile_image_url;
        } else {
          return this.collection.hero_image_url;
        }
      }
    },
    heroStyle() {
      if (!_.isEmpty(this.heroBackgroundImage)) {
        return {
          backgroundImage: `url(${this.heroBackgroundImage})`
        }
      }
    },
    dynamicMainText() {
      if (!_.isEmpty(this.collection) && !_.isEmpty(this.collection.hero_title)) {
        return this.collection.hero_title;
      }

      if (this.mainText) {
        return this.mainText;
      }

      return `RENT DESIGNER ${this.filterValue.toUpperCase()}`
    },
    dynamicSubText() {
      if (!_.isEmpty(this.collection) && !_.isEmpty(this.collection.hero_subtitle)) {
        return this.collection.hero_subtitle;
      }

      if (this.subText) {
        return this.subText;
      }

      return `Borrow bold and dazzling ${this.filterValue} to make a statement at your next special occasion.`
    },
    dynamicBtnText() {
      if (!_.isEmpty(this.collection) && !_.isEmpty(this.collection.hero_btn_text)) {
        return this.collection.hero_btn_text;
      }

      if (this.btnText) {
        return this.btnText;
      }

      return `BROWSE ALL ${this.filterValue.toUpperCase() }`
    },
    dynamicBtnLink() {
      if (!_.isEmpty(this.collection) && !_.isEmpty(this.collection.hero_btn_url)) {
        return this.collection.hero_btn_url;
      }

      return `/rent-jewelry?${this.filterKind}=${this.filterValue}`;
    },
    heroBannerStyle() {
      if (!_.isEmpty(this.collection) && !_.isEmpty(this.collection.hero_bg_color)) {
        return {
          backgroundColor: this.collection.hero_bg_color
        }
      }

      return {}
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  }
}
</script>