<template>
  <div>
    <FocusedList 
      v-if="!loading"
      :products="newArrivals"
      :isUser="isUser"
      :title="'New Arrivals'"
      :subTitle="'Rent our exquisite and most recently launched collection of fine jewelry.'"
      class="with-bg-green"
    />
  </div>
</template>

<script>
import * as Toastr from 'toastr';
import FocusedList from "../products/focused_list.vue";

export default {
  name: 'HowItWorksProducts',
  components: {
    FocusedList
  },
  data() {
    return {
      loading: true,
      products: [],
      isUser: false
    }
  },
  computed: {
    newArrivals() {
      return this.products.slice(0, 8);
    }
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      try {
        this.loading = true;
        $.ajax({
          method: "GET",
          url: "/get-products?collections_slugs=new-arrivals-carousel-rent-jewelry-page-top",
          success: (data) => {
            this.products = [];
            this.products = [...data.products];
            
            this.isUser = data.is_user;
            this.products.forEach((el) => {
              el.rental_price.cents = (el.rental_price.cents / 100).toFixed(2);
              el.market_price.cents = (el.market_price.cents / 100).toFixed(2);
              el.fav_href = "/favorites/" + el.slug;
            });
            this.loading = false;
          },
          error: function (res) {
            Toastr.error(res.statusText, "Error fetching products.");
            this.loading = false;
          },
        });
      
      } catch (e) {
        Toastr.error("Error fetching products");
        this.loading = false;
      }
    },
  }
}
</script>