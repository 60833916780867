<template>
  <div>
    <template v-if="isEdit">
      <p v-if="user.guest" class="mb-10 text-color-red">
        First time customers, please create a user account
      </p>

      <div class="is-flex names">
        <div class="form-item mr-input" :class="formItemClass($v.fields.first_name)">
          <label for="first-name">FIRST NAME</label>
          <input
            v-model.lazy="$v.fields.first_name.$model"
            type="text"
            name="first-name"
            id="first-name"
            @input="$v.fields.first_name.$reset()"
            @blur="$v.fields.first_name.$touch()"
          />
        </div>
        <div class="form-item" :class="formItemClass($v.fields.last_name)">
          <label for="last-name">LAST NAME</label>
          <input
            v-model="$v.fields.last_name.$model"
            type="text"
            name="last-name"
            id="last-name"
            @input="$v.fields.last_name.$reset()"
            @blur="$v.fields.last_name.$touch()"
          />
        </div>
      </div>

      <div class="is-flex credentials">
        <div class="form-item mr-input" :class="formItemClass($v.fields.email)">
          <label for="email">Email</label>
          <input
            v-model="$v.fields.email.$model"
            type="text"
            name="email"
            id="email"
            @input="$v.fields.email.$reset()"
            @blur="$v.fields.email.$touch()"
          />
        </div>
        <div class="form-item" :class="formItemClass($v.fields.password)">
          <label for="password">Password</label>
          <input
            v-model="$v.fields.password.$model"
            type="password"
            name="password"
            id="password"
            @input="$v.fields.password.$reset()"
            @blur="$v.fields.password.$touch()"
          />
        </div>
      </div>

      <div class="save-container">
        <button @click="save">SAVE AND CONTINUE</button>
      </div>
    </template>

    <template v-else class="form-details">
      <div class="shipping-address">
				<p>
          <b>Name:</b>{{ user.first_name }} {{ user.last_name }}
          <br />
				  <strong>Email:</strong>{{ user.email }}
        </p>
			</div>
    </template>
  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api"

import * as Toastr from "toastr";
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'AppCheckoutStepsAccount',
  props: {
    user: { type: Object, required: true },
    account_path: { type: String, required: true }
  },
  data() {
    return {
      isEdit: !true,
      fields: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      }
    };
  },
  validations: {
    fields: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) }
    }
  },
  mounted() {
    this.prefillFields();
  },
  methods: {
    prefillFields() {
      if (this.user.guest) {
        this.fields.first_name = '';
        this.fields.last_name = '';
        this.fields.email = '';
        this.fields.password = '';

        this.isEdit = true;
        
        return 
      }

      this.fields.first_name = this.user.first_name;
      this.fields.last_name = this.user.last_name;
      this.fields.email = this.user.email;
    },
    formItemClass(field) {
      return {
        'mr-input': true,
        'error': field.$error
      }
    },
    save() {
      this.$v.fields.$touch();

      if (this.$v.fields.$invalid) {
        Toastr.error('Please fill in all required fields.');
        return;
      }
      
      $.ajax({
        method: "PUT",
        data: {
          account: this.fields,
          user: {}
        },
        url: this.account_path,
        success:(data) => {
          this.isEdit = false;
          this.$emit('account-updated', JsonApi.deserialize(data.user));
          Toastr.success("Account updated successful!");
        },
        error:(_data) => {
          Toastr.error("There was an error updating your account.");
        }
      });
    }
  }
};

</script>