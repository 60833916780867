<template>
  <div class="checkout-container" :id="planId">
    <div class="nav" id="nav-desktop">
    </div>
    <div class="main-box">
      <div class="checkout-form">
        <h1 class="checkout-title">SECURE CHECKOUT</h1>
        <div class="accordions">
          <div v-if="user.guest" class="accordion">
            <div class="ac-title">
              <p class="checkout-number">{{ dynamicStepNumber(0) }}</p>
              <span>Account Information</span>
            </div>

            <p class="mb-10">
              Existing customers, please login.
            </p>
            <div class="mb-45">
              <a class="btn btn-primary px-45" data-toggle="modal" data-target="#modal-login">login</a>
            </div>

            <StepAccount 
              :user="user"
              :account_path="account_path"
              @account-updated="stepAccountCompleted"
            />
          </div>
          <div class="spacer" />

          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">{{ dynamicStepNumber(1) }}</p>
              <span>Shipping Information</span>
            </div>
            
            <shipping
              :class="shippingClass"
              :states="usaStates"
              :addresses="shipping_addresses"
              :user="user"
              :rent_at="rent_at"
              :return_at="return_at"
              :securityCheck="securityCheck"
              :update_recipient_path="payment.path"
              :order="order"
              @openVerification="verificationOpened = true"
            />
          </div>
          <div class="spacer" />
          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">{{ dynamicStepNumber(2) }}</p>
              <span>Identity Verification</span>
            </div>
            <verification
              :user="user"
              :addresses="shipping_addresses"
              :securityCheck="securityCheck"
              :personaEnvironment="persona_environment"
              :personaTemplateId="persona_template_id"
              :personaEnvironmentId="persona_environment_id"
              :verificationOpened="verificationOpened"
              :returningCustomer="returning_customer"
            />
          </div>
          <div class="spacer" />
          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">{{ dynamicStepNumber(3) }}</p>
              <span>Billing Information</span>
            </div>
            <billing
              :states="usaStates"
              :addresses="billing_addresses"
              :shippingAddress="shipping_addresses[0]"
              :payment="payment"
              :securityCheck="securityCheck"
              :order="order"
              :update_recipient_path="payment.path"
              :user="user"
            />
          </div>
          <div class="spacer" />
          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">{{ dynamicStepNumber(4) }}</p>
              <span>Review Order</span>
            </div>
            <div class="collapsed" id="checkout-review">
              <p>
                Please review your order, accept Rental Terms and Conditions, and click PLACE ORDER button when ready.
              </p>
              <div class="checkboxes">
                <div>
                  <input
                    v-model="securityCheck.terms"
                    name="agreement"
                    type="checkbox"
                  />
                  <label for="agreement">
                    I have read and accept the 
                    <a class="tos-link" href="/terms-and-conditions">
                      Rental Terms and Conditions
                    </a>
                    .
                  </label>
                </div>
                <div>
                  <input
                    v-model="securityCheck.disclosure"
                    name="disclosure"
                    type="checkbox"
                  />
                  <label for="disclosure">
                    Due to the nature of services that Trejours provides, Trejours reserves the right to reject jewelry rental services to individuals, who do not meet minimum requirements for good credit qualifications and/or do not pass background or identity verification. In the event that a user is not approved for services based on the above criteria, an order cancellation fee may apply. 
                  </label>
                </div>
              </div>
              <div class="save-container">
                <button id="submit-order">PLACE ORDER</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <order-details
        :products="items"
        :order="order"
        :rent_at="rent_at"
        :return_at="return_at"
        :isSubscription="isSubscription"
      />
    </div>
  </div>
</template>

<script>
import * as Toastr from "toastr";

import OrderDetails from "./order_details";

import StepAccount from "./steps/account";
import Shipping from "./steps/shipping";
import Billing from "./steps/billing";
import Verification from "./steps/verification";

import JsonApi from "../../../vendor/json-api";
import { states } from "../../../../data/checkoutData.js";
import GtmEvents from "../../gtm_events";


export default {
  name: 'AppCheckout',
  props: {
    payment: { type: Object, required: true },
    shipping_addresses: { type: Array, required: true },
    billing_addresses: { type: Array, required: true },
    new_address_path: { type: String },
    account_path: { type: String, required: true },
    order: { type: Object, required: true },
    user: { type: Object, required: true },
    persona_environment: { type: String, required: true },
    persona_template_id: { type: String, required: true },
    persona_environment_id: { type: String, required: true },
    returning_customer: { type: Boolean, required: true }
  },
  components: {
    "order-details": OrderDetails,
    shipping: Shipping,
    verification: Verification,
    billing: Billing,
    StepAccount
  },
  computed: {
    planId() {
      if (this.order.shopping_mode === "rbtd") {
        return "";
      } else {
        return "sub-checkout";
      }
    },
    isSubscription() {
      if (this.order.shopping_mode !== "rbtd") {
        return true;
      } else {
        return false;
      }
    },
    shippingClass() {
      // this step is hidden only when account step is required
      return this.currentStep == 'account' ? "collapsed" : "";
    }
  },
  data() {
    return {
      items: JsonApi.deserialize(gon.payload.order_products).data,
      usaStates: states,
      rent_at: "",
      return_at: "",
      securityCheck: {
        shipping: false,
        verification: false,
        billing: false,
        terms: false,
        disclosure: false,
      },
      verificationOpened: false,
      currentStep: 'shipping' // account, shipping, verification, billing
    };
  },
  watch: {
    'user.guest'(isGuest) {
      if (isGuest) {
        this.currentStep = 'account';
      }
    },
  },
  created() {
    if (!this.user.email_verified) {
      Toastr.error("Please confirm Your e-mail before proceeding!");
      setTimeout(() => {
        document.location.href = "/";
      }, 3000);
    }

    GtmEvents.trackCartEvents('begin_checkout')

    this.rent_at = this.formatDate(this.order.rent_at, -0);
    this.return_at = this.formatDate(this.order.return_at, 0);
  },
  mounted() {
    if (this.user.guest) {
      this.currentStep = 'account';
    }
  },
  methods: {
    dynamicStepNumber(number) {
      return this.user.guest ? number + 1 : number;
    },
    stepAccountCompleted(user) {
      this.currentStep = "shipping"
      this.$emit('account-updated', user)
    },
    getOrdinalNum: function (number) {
      let selector;

      if (number <= 0) {
        selector = 4;
      } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
      } else {
        selector = number % 10;
      }

      return number + ["th", "st", "nd", "rd", ""][selector];
    },
    getMonthShortName: function (num) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[num];
    },
    formatDate: function (date, daysOffset) {
      const dateObj = new Date(date);
      dateObj.setDate(dateObj.getDate() + daysOffset);
      return `${this.getOrdinalNum(dateObj.getDate())} ${this.getMonthShortName(
        dateObj.getMonth()
      )}, ${dateObj.getFullYear()}`;
    },
  },
};
</script>

<style lang="scss">
</style>
