<template>
  <div class="wrapper-new-arrivals py-45">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <div class="focus-title my-10">
            <h1 class="main-text mb-5 text-left">
              {{ title }}
            </h1>
            <p class="sub-text text-left">
              {{ subTitle }}
            </p>
          </div>
        </div>

        <div class="col-md-10">
          <div class="products-container"></div>
            <VueSlickCarousel v-if="sliderVisible && anyProducts" v-bind="sliderSettings">
              <ProductItem
                v-for="(product, index) in products" :key="index"
                :product="product"
                :isUser="isUser"
                @favoriteHandler="favoriteHandler(product)"
              />
            </VueSlickCarousel>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import ProductItem from './item.vue'

export default {
  name: 'ProductFocusedList',
  components: {
    ProductItem,
    VueSlickCarousel
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sliderVisible: false,
      sliderSettings: {
        "dots": true,
        "arrows": false,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 4,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 4,
            }
          },
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
            }
          }
        ]
      }
    }
  },
  computed: {
    anyProducts() {
      return this.products.length > 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.sliderVisible = true
    }, 1000)
  },
  methods: {
    favoriteHandler(product) {
      this.$emit('favoriteHandler', product)
    }
  }
}
</script>

<style scoped>
/deep/ .slick-slide {
  padding: 0 10px;
}
</style>