<template lang="html">
  <div class="newsletter-container">
    <input
      v-model="email"
      @keyup.enter="subscribeEmail"
      type="email"
      name="email"
      id="newsletter-email"
      placeholder="YOUR EMAIL ADDRESS HERE"
    />
    <button @click="subscribeEmail">
      <img src="~images/app/right-arrow.webp" v-show="!loading" />
      <p v-show="loading">Loading...</p>
    </button>
  </div>
</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: {
    path: { type: String, required: true },
  },
  data() {
    return {
      email: '',
      loading: false,
    };
  },

  methods: {
    subscribeEmail() {
      var that = this;

      if (that.loading) {
        return;
      }
      that.loading = true;

      if (that.email == '') {
        Toastr.error('Please enter your email');
        that.loading = false;
        return;
      }

      $.ajax({
        method: 'POST',
        data: {
          newsletter: {
            email: that.email,
          },
        },
        url: that.path,
        success: function(data) {
          that.loading = false;
          that.email = '';
          Toastr.success(
            'You have successfully subscribed to our newsletter. Please check your email inbox!',
            'Subscription successfull'
          );
        },
        error: function(data) {
          that.loading = false;
          Toastr.error(data.responseJSON.error, 'Subscription error');
        },
      });
    },
  },
};
</script>

<style lang="css"></style>
